@media (max-width: 1000px) {
  .SidebarItems {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    position: fixed;
    top: 10px;
    right: 0;
    z-index: 100;
  }

  .nav-menu {
    padding-top: 15px;
    position: absolute;
    right: -100%;
    top: 30px;
    height: 100%;
    width: 150px;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 22px;
    list-style-type: none;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
  }

  .nav-menu-active {
    padding-top: 15px;
    position: absolute;
    right: 0;
    top: 30px;
    background-color: #232323;
    height: 100%;
    width: 150px;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 22px;
    list-style-type: none;
    transition: all 0.5s ease;
    opacity: 1;
  }

  .menu-icon {
    font-size: 25px;
    color: #f9f9f9;
    margin-right: 15px;
    margin-top: 5px;
  }

  .fa-times {
    font-size: 30px;
  }

  .side-bar-item {
    font-family: League Spartan;
    font-size: 20px;
    color: #f9f9f9;
  }

  .link-container {
    width: 100px;
  }
}
