.project-layout2 {
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}

.project-card {
  position: relative;
  flex: 2 2 calc(50% - 30px);
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  flex-direction: column;
  max-width: 600px;
  min-width: 100px;
  min-height: 350px;
  padding: 15px 16px;
  gap: 16px;
  border-radius: 5px;
  background: var(--portfolio-elevation, #232323);
}

.title-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}

.link-out {
  height: 25px;
  width: 25px;
}

.project-title {
  color: var(--portfolio-white, #f9f9f9);
  font-family: Barlow;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

/* Transition for link */
.project-title:hover {
  transition: ease-in-out;
  transition: 0.6s;
  color: #99a8ff;
}

.project-description {
  color: var(--portfolio-white, #f9f9f9);
  font-family: League Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
  line-height: 120%;
}

hr.rounded {
  border: 2px solid #99a8ff;
  border-radius: 5px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: -2px;
}

.card-shield-container {
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  display: flex;
  align-content: center;
  align-self: flex-start;
  gap: 10px;
  flex-wrap: wrap-reverse;
  row-gap: 5px;
}
