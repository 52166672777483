/* @import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Birthstone&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"); */

/* Body element that encloses */

@font-face {
  font-family: "Barlow";
  src: url("../public/fonts/Barlow-Regular.woff2") format("woff2"),
    url("../public/fonts/Barlow-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Barlow";
  src: url("../public/fonts/Barlow-Regular.woff2") format("woff2"),
    url("../public/fonts/Barlow-Regular.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* Barlow done */

@font-face {
  font-family: "BirthStone";
  src: url("../public/fonts/Birthstone-Regular.woff2") format("woff2"),
    url("../public/fonts/Birthstone-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BirthStone";
  src: url("../public/fonts/Birthstone-Regular.woff2") format("woff2"),
    url("../public/fonts/Birthstone-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "BirthStone";
  src: url("../public/fonts/Birthstone-Regular.woff2") format("woff2"),
    url("../public/fonts/Birthstone-Regular.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* Birthstone done */

@font-face {
  font-family: "League Spartan";
  src: url("../public/fonts/LeagueSpartan-Light.woff2") format("woff2"),
    url("../public/fonts/LeagueSpartan-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "League Spartan";
  src: url("../public/fonts/LeagueSpartan-SemiBold.woff2") format("woff2"),
    url("../public/fonts/LeagueSpartan-SemiBold.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "League Spartan";
  src: url("../public/fonts/LeagueSpartan-SemiBold.woff2") format("woff2"),
    url("../public/fonts/LeagueSpartan-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  margin-top: -3em;
  overflow-x: hidden;
}

.App {
  margin: 0;
  overflow-x: hidden;
  background-color: #121212;
  display: block;
  padding-left: 7%;
  padding-right: 7%;
  scroll-behavior: smooth;
  scrollbar-color: #121212;
  width: 101.9%;
  box-sizing: border-box;
}

/* Navitation bar */
.navbar {
  background-color: #232323;
  display: flex;
  align-items: center;
  color: #f9f9f9;
  font-weight: 600;
  font-size: 130%;
  height: 60px;
  flex-shrink: 1;
  padding: none;
  font-family: "League Spartan", sans-serif;
  border: none;
  padding: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

a {
  margin: 1.5%;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  position: relative;
}

a::after {
  content: "";
  position: absolute;
  bottom: -4px;
  height: 2px;
  width: 100%;
  left: 0;
  background-color: #99a8ff;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transform: scaleX(0);
}

a:hover::after {
  transform: scaleX(1);
}

.navbar-items:hover {
  transition: 0.5s;
  color: #99a8ff;
}

.as {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.signature {
  font-family: "BirthStone", sans-serif;
  font-size: 140%;
  color: #99a8ff;
  margin-left: 4.5%;
  margin-right: 3%;
  transform: translateY(-0.7px);
  cursor: pointer;
}

.seperator {
  font-size: 190%;
  font-weight: 300;
  margin: none;
  padding: none;
  transform: translateY(5%);
  cursor: unset;
}

.seperator::after {
  display: none;
}

/* About me section */
.about-me {
  margin-top: 180px;
  font-family: "League Spartan", sans-serif;
  color: #f9f9f9;
  display: block;
  margin-bottom: 140px;
}

.title {
  font-family: "Barlow", sans-serif;
  color: #99a8ff;
  font-size: 140%;
  margin: 0;
  font-weight: 500;
}

.name {
  font-family: "Barlow", sans-serif;
  margin-bottom: 0.7%;
  font-size: 300%;
  font-weight: 500;
  margin-top: 40px;
}

.about-me-image-text-div {
  display: grid;
  column-gap: 8%;
  grid-template-columns: 8fr 6fr;
  justify-items: center;
  justify-content: center;
}

.portrait {
  object-fit: contain;
  width: 400px;
  height: auto;
}

.about-me-intro {
  line-height: 140%;
  margin-top: 0;
  font-size: 140%;
  font-family: "League Spartan";
  font-weight: 300;
}

.purple-headers-generic {
  color: #99a8ff;
  justify-content: center;
  align-self: start;
}

.projects-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 140px;
}

.projects-layout {
  font-family: "League Spartan", sans-serif;
  color: #f9f9f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 106%;
}

.project {
  background-color: #232323;
  border-radius: 1%;
  width: 360px;
  height: 380px;
  margin: 2%;
  padding: 1.5%;
  flex-wrap: wrap;
}

.project-title {
  font-family: "Barlow";
  font-weight: 600;
  font-size: 180%;
  display: block;
  margin-top: 0px;
  margin-bottom: 18px;
}

.project-content {
  margin-top: 18px;
  line-height: 120%;
  font-size: 120%;
  font-weight: 500;
  font-family: "League Spartan", sans-serif;
  font-weight: 300;
}

.technical-skills-section {
  font-family: "Barlow", sans-serif;
  color: #99a8ff;
}

.purple-headers-generic2 {
  color: #99a8ff;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 4%;
}

.purple-mini-header {
  font-size: 180%;
  font-weight: 500;
}

.shield-container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.shield {
  width: 100%;
  height: 35px;
  margin: 0;
}

.shield-component {
  padding: 0;
  width: fit-content;
}

.contact-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  width: 100%;
  margin-top: 160px;
  margin-bottom: 12%;
}

form {
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  padding: 3px;
}

textarea {
  width: 100%;
  height: 200px;
  resize: vertical; /* Allow vertical resizing */
  border-width: 10px;
  border-color: #d9d9d9;
  background-color: #232323;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 12px;
  resize: none;
  padding: 7px;
  border-radius: 6px;
  box-sizing: border-box; /* This ensures that padding and border are included in the total width */
  font-family: "Barlow", sans-serif;
  color: #ffffff;
  font-size: 16px;
}

input {
  width: 100%;
  padding: 7px;
  height: 50px;
  border-width: 1px;
  border-color: #d9d9d9;
  border-style: solid;
  box-sizing: border-box;
  background-color: #232323;
  border-radius: 6px;
  font-family: "Barlow", sans-serif;
  color: #ffffff;
  font-size: 16px;
}

::placeholder {
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  color: #d9d9d9;
}

.submit-button {
  border-width: 2px;
  border-color: #99a8ff;
  border-style: solid;
  border-radius: 6px;
  padding: 10px;
  background-color: #99a8ff;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  width: 120px;
  align-self: flex-end;
  position: relative; /* Allows to offset the position of where it would nomally be using top, bottom etc, but item remains in current position in document flow  */
  z-index: 2;
}

.submit-button::before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  border-radius: 6px;
  border: 3px solid;
  border-color: #99a8ff;
}

.submit-button:hover::before,
.submit-bitton:focus::before {
  top: -8px;
  right: -8px;
  left: -8px;
  bottom: -8px;
  transition: 0.5s;
}

.submit-button:active {
  background-color: #b3bcf9;
}

.joiner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-content: flex-start;
  gap: 13px;
  margin-bottom: 12px;
}

.name-input,
.email-input {
  width: 100%;
  box-sizing: border-box;
}

.name-input {
  grid-column: 1;
}

input:focus,
textarea:focus {
  border-color: #99a8ff;
  transition: 0.4s;
  opacity: 0.8;
}

.email-input {
  grid-column: 2;
}

.contact-writing {
  font-family: "Barlow", sans-serif;
  color: #99a8ff;
  font-weight: lighter;
  font-size: 150%;
  font-weight: 400;
  margin-top: 20px;
  min-width: 1px;
}

h1 {
  font-weight: 500;
  font-size: 50px;
}

.contact-description {
  padding-left: 4px;
  line-height: 140%;
  color: #d9d9d9;
  margin-top: 2.5%;
  white-space: normal;
  font-size: 22px;
  font-family: "League Spartan", sans-serif;
  font-weight: lighter;
}

.adjust {
  height: fit-content;
  width: 100%;
}

.hidden {
  display: none;
}

.SidebarItems {
  display: none;
}

/* For mobile screen  */
@media (max-width: 700px) {
  body {
    overflow-x: hidden;
  }

  .about-me {
    margin-top: 100px;
    margin-bottom: 10px;
  }

  .name {
    font-size: 250%;
  }

  .title {
    font-size: 120%;
  }

  .about-me-intro {
    margin-top: 30px;
    font-size: 120%;
    line-height: 25px;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    text-align: start;
    justify-content: start;
  }

  .projects-section {
    align-items: flex-start;
    margin-bottom: 60px;
  }

  .purple-mini-header {
    font-size: 20px;
    justify-content: start;
  }

  .purple-headers-generic {
    align-self: flex-start;
    margin-top: 0;
  }

  .purple-headers-generic2 {
    text-align: start;
    margin-top: 0;
  }

  .contact-section {
    margin-top: 20px;
  }

  .project-card {
    height: 450px;
  }
}

/* For navigation bar, and profile pic adjustment*/
@media (max-width: 1000px) {
  body {
    box-sizing: border-box;
    width: 100%;
  }

  .about-me-image-text-div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    justify-items: center;
  }

  .name {
    text-align: center;
    margin-top: 10px;
  }

  .about-me {
    margin-top: 120px;
  }

  .title {
    text-align: center;
  }

  .portrait {
    object-fit: cover;
    grid-row: 1;
    object-position: 50% 50%;
    height: 350px;
    width: 300px;
    border-radius: 10%;
  }

  .contact-section {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .contact-writing {
    grid-row: 1;
  }

  .contact-description {
    padding: 0;
  }

  .adjust {
    width: 100%;
    max-width: none;
  }
  .submit-form {
    grid-row: 2;
  }

  .navbar-items {
    display: none;
  }

  .seperator {
    display: none;
  }

  a::after {
    display: none;
  }

  .contact-section {
    margin-top: 10%;
  }
}
